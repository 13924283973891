import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/post-layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`A few days ago, I went through a course with Steve Kinney on `}<a parentName="p" {...{
        "href": "https://frontendmasters.com/courses/programming-language/"
      }}>{`building your own language`}</a>{`. In the course, we build a basic compiler, which takes an input string (we used a LISP-ish language with a `}<inlineCode parentName="p">{`(add 3 4 5)`}</inlineCode>{` syntax), `}<em parentName="p">{`tokenizes`}</em>{` it, `}<em parentName="p">{`parses`}</em>{` the tokens into an Abstract Syntax Tree (AST), and then `}<em parentName="p">{`generates`}</em>{` code from that tree.`}</p>
    <p>{`The steps look like:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`const input = '(add 3 4)'

const tokens = tokenize(input)
// yields:
[
  {type: 'Parenthesis', value: '('},
  {type: 'Name', value: 'add'},
  {type: 'Number', value: 3},
  {type: 'Number', value: 4},
  {type: 'Parenthesis', value: ')'}
]

const tree = parse(tokens)
// yields:
{
  type: 'CallExpression',
  name: 'add',
  arguments: [
    { type: 'NumericLiteral', value: 3 },
    { type: 'NumericLiteral', value: 4 },
  ],
}

const generated = generate(tree)
// yields:
'add(3, 4)'
`}</code></pre>
    <p>{`There are many other steps involved, and we built only a super simple language that can do basic computation and define variables. By no means do I fully "understand" "literally any" of this. But! The course was super informative. And it hits the same concepts that Gary Bernhardt covers in his `}<a parentName="p" {...{
        "href": "https://www.destroyallsoftware.com/screencasts/catalog/a-compiler-from-scratch"
      }}>{`Compiler From Scratch`}</a>{` webcast on on `}<a parentName="p" {...{
        "href": "https://www.destroyallsoftware.com/"
      }}>{`Destroy All Software`}</a>{`.`}</p>
    <p>{`The part of `}<a parentName="p" {...{
        "href": "https://frontendmasters.com/courses/programming-language/"
      }}>{`building your own language`}</a>{` that made me want to write about transforming JSON to JSX was when we got to the code generation step. Steve built the language to conform to what `}<a parentName="p" {...{
        "href": "https://babeljs.io/"
      }}>{`Babel`}</a>{` - that tool that I am always relying on to make my JavaScript work - can read and work with.`}</p>
    <p>{`I'd never really taken the time to peek under the hood on what Babel is doing, so having a Smart Dude show me the basics made it seem a lot less intimidating. At its core, Babel's just compiling JavaScript into JavaScript. It reads in next-generation JavaScript input, tokenizes it, parses those tokens into an abstract syntax tree, and ultimately generates older, safer, more-browsers-compliant JavaScript.`}</p>
    <p>{`Since I now knew the steps to make a Babel-compliant abstract syntax tree (AST), I wanted to see if I could turn a JSON object that holds data about the markup structure of a page into a JSX string.`}</p>
    <h2>{`The goal`}</h2>
    <p>{`The goal is to turn an input like:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`// json input
{
  "root": {
    "type": 'div',
    "props": {
      "className": 'hello',
      "numberThing": 5,
      "isTrue": true
    },
    "innerText": 'this is the text',
    "children": [] // we would specify child components here, but we're not going to worry about them for now
  }
}
`}</code></pre>
    <p>{`into a React string that looks like:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`<div className="hello" numberThing={5} isTrue={true}>this is the text</div>
`}</code></pre>
    <p>{`And in order to make that transformation, I need to use Babel's `}<inlineCode parentName="p">{`generate`}</inlineCode>{` functionality on an AST that I build. The code is going to look something like:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`// json-to-jsx.js
const generate = require('@babel/generator').default;

const toJSX = input => {
  const parsed = JSON.parse(input) // parsing is solved!
  const ast = ????
  return generate(ast).code; // generation is solved!
}
`}</code></pre>
    <h2>{`The how`}</h2>
    <p>{`With `}<inlineCode parentName="p">{`JSON.parse`}</inlineCode>{` and Babel's `}<inlineCode parentName="p">{`generate`}</inlineCode>{`, we've got the parsing and generating steps already taken care of. And since we're already assuming that we've got some JSON input, we don't have any tokenizing to work with, either. So, we've got the tokenizing, parsing, and generation all already handled for us. The only problem is that when JSON parses a string into a JavaScript object, the object tree is not in a form that Babel sees as JSX. After the JSON parse, we have:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`{
  root: {
    type: 'div',
    props: {
      className: 'hello',
      numberThing: 5,
      isTrue: true
    },
    innerText: null,
    children: []
  },
}
`}</code></pre>
    <p>{`Babel can certainly read that as a JS object with property names and values. But it has absolutely no idea that my `}<inlineCode parentName="p">{`type: div`}</inlineCode>{` is something that I want to translate into a JSX `}<inlineCode parentName="p">{`<div></div>`}</inlineCode>{` tag.`}</p>
    <p>{`What we need to do, then, is `}<em parentName="p">{`transform`}</em>{` this parsed JavaScript object into an AST that Babel recognizes as JSX.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`// json-to-jsx.js
const generate = require('@babel/generator').default;

const transform = ({input}) => {
  // ???
}

const toJSX = input => {
  const parsed = JSON.parse(input) // parsing is solved!
  const ast = transform({input: parsed}) // transform our parsed JS object
  return generate(ast).code; // generation is solved!
}
`}</code></pre>
    <p>{`In order to figure out what transforms we need to make, we can work backwards. We'll start with a valid JSX string and explore the AST structure that the string breaks down to. Then, once we know the shape we have to build, we can start to manipulate our JS object input into the correct structure.`}</p>
    <h3>{`Learn the AST shape we need to build`}</h3>
    <p>{`So, first up, let's see that code as an AST. To do so, there's an awesome tool called `}<a parentName="p" {...{
        "href": "https://astexplorer.net/"
      }}>{`AST Explorer`}</a>{` that `}<a parentName="p" {...{
        "href": "https://github.com/fkling"
      }}>{`@fkling`}</a>{` built that allows us look into exactly what Babel sees when it sees the above JSX code.`}</p>
    <p>{`The AST for the above JS object looks like this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`{
  type: "JSXElement",
  openingElement: {
    type: "JSXOpeningElement",
    name: {
      type: "JSXIdentifier",
      name: "div"
    },
    attributes: [
      {
        type: "JSXAttribute",
        name: {
          type: "JSXIdentifier",
          name: "className"
        },
        value: {
          type: "StringLiteral",
          value: "hello"
        }
      },
      {
        type: "JSXAttribute",
        name: {
          type: "JSXIdentifier",
          name: "numberThing"
        },
        value: {
          type: "JSXExpressionContainer",
          expression: {
            type: "NumericLiteral",
            value: 5
          }
        }
      },
      {
        type: "JSXAttribute",
        name: {
          type: "JSXIdentifier",
          name: "isTrue"
        },
        value: {
          type: "JSXExpressionContainer",
          expression: {
            type: "BooleanLiteral",
            value: true
          }
        }
      }
    ],
    selfClosing: false
  },
  closingElement: {
    type: "JSXClosingElement",
    name: {
      type: "JSXIdentifier",
      name: "div"
    }
  },
  children: [
    {
      type: "JSXText",
      value: "this is the text"
    }
  ]
}
`}</code></pre>
    <p>{`Mmmkay, so not exactly what I'd call terse. The verbosity might make it look more intimidating (and this is the AST without any metadata about where bits of code start and end), but this structure allows Babel to represent every possible bit of JavaScript that we could write. Just from a few dozen different building block types with some properties!`}</p>
    <h3>{`Enumerate the types of nodes we need to build`}</h3>
    <p>{`Now that we have the AST that we're targeting, we need to look at each of the types we're trying to build. In this AST, we've got ten different types of node that we need to build up. And looking at the values inside of each of those nodes, we can hazard a guess at what each type is responsible for.`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`JSXElement`}</inlineCode>{`: The overall parent container`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`JSXOpeningElement`}</inlineCode>{`: The name of the element (`}<inlineCode parentName="li">{`div`}</inlineCode>{`), along with some attributes about the element (our `}<inlineCode parentName="li">{`props`}</inlineCode>{`)`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`JSXClosingElement`}</inlineCode>{`: The name for the closing tag of the element (`}<inlineCode parentName="li">{`div`}</inlineCode>{`)`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`JSXAttribute`}</inlineCode>{`: A list on the `}<inlineCode parentName="li">{`JSXOpeningElement`}</inlineCode>{` that enumerates the `}<inlineCode parentName="li">{`props`}</inlineCode>{` of the element`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`JSXText`}</inlineCode>{`: The text markup of the element (`}<inlineCode parentName="li">{`innerText`}</inlineCode>{` of `}<inlineCode parentName="li">{`this is the text`}</inlineCode>{`)`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`JSXIdentifier`}</inlineCode>{`: Looks like the way that Babel marks the prop names and the tag name of the element`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`JSXExpressionContainer`}</inlineCode>{`: Looks like the `}<inlineCode parentName="li">{`{}`}</inlineCode>{` in a prop declaration like `}<inlineCode parentName="li">{`numberThing={5}`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`BooleanLiteral`}</inlineCode>{`: The `}<inlineCode parentName="li">{`true`}</inlineCode>{` or `}<inlineCode parentName="li">{`false`}</inlineCode>{` value of a boolean`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`NumericLiteral`}</inlineCode>{`: A numeric value - the `}<inlineCode parentName="li">{`5`}</inlineCode>{` in our `}<inlineCode parentName="li">{`numberThing`}</inlineCode>{` prop`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`StringLiteral`}</inlineCode>{`: A string value - the `}<inlineCode parentName="li">{`hello`}</inlineCode>{` value of our `}<inlineCode parentName="li">{`className`}</inlineCode>{` prop`}</li>
    </ul>
    <h3>{`Write helpers to help us build each type`}</h3>
    <p>{`When we're building up this AST, we need to know which node types are children of which types, as well as which nodes are required to build each type. For this, we can go to the source that defines all of the `}<a parentName="p" {...{
        "href": "https://github.com/babel/babel/blob/master/packages/babel-types/src/definitions/core.js"
      }}>{`core`}</a>{` and `}<a parentName="p" {...{
        "href": "https://github.com/babel/babel/blob/master/packages/babel-types/src/definitions/jsx.js"
      }}>{`JSX`}</a>{` types that the Babel work with.`}</p>
    <p>{`We'll start at the leaf nodes of what the AST explorer spit out and build some helpers as we go. First up are the non-JSX types.`}</p>
    <h4>{`Boolean, String, and Numeric literals`}</h4>
    <p>{`For the `}<a parentName="p" {...{
        "href": "https://github.com/babel/babel/blob/master/packages/babel-types/src/definitions/core.js#L558"
      }}><inlineCode parentName="a">{`BooleanLiteral`}</inlineCode></a>{`, we see a type definition like this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`defineType("BooleanLiteral", {
  builder: ["value"],
  fields: {
    value: {
      validate: assertValueType("boolean"),
    },
  },
  aliases: ["Expression", "Pureish", "Literal", "Immutable"],
});
`}</code></pre>
    <p>{`In reading this, we see in both the `}<inlineCode parentName="p">{`builder`}</inlineCode>{` and `}<inlineCode parentName="p">{`fields`}</inlineCode>{` that the required field on the node is a `}<inlineCode parentName="p">{`value`}</inlineCode>{`, which is of type boolean.`}</p>
    <p>{`So, wherever we see a `}<inlineCode parentName="p">{`true`}</inlineCode>{` or `}<inlineCode parentName="p">{`false`}</inlineCode>{` as a value in our input JS object, we need to transform it into:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`{
  type: \`BooleanLiteral\`,
  value: true|false
}
`}</code></pre>
    <p>{`The `}<inlineCode parentName="p">{`StringLiteral`}</inlineCode>{` and `}<inlineCode parentName="p">{`NumericLiteral`}</inlineCode>{` are very similar:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`// String literals
{
  type: 'StringLiteral',
  value: 'any string'
}
// Numeric literals
{
  type: 'NumericLiteral',
  value: 5 // any number
}
`}</code></pre>
    <p>{`We can build some helper functions for each of these:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`// json-to-jsx.js
const generate = require('@babel/generator').default;

// Helpers
// NEW!
const buildStringLiteral = value => ({type: 'StringLiteral', value})
const buildBooleanLiteral = value => ({type: 'BooleanLiteral', value})
const buildNumericLiteral = value => ({type: 'NumericLiteral', value})


const transform = ({input}) => {
  // ???
}

const toJSX = input => {
  const parsed = JSON.parse(input) // parsing is solved!
  const ast = transform({input: parsed}) // transform our parsed JS object
  return generate(ast).code; // generation is solved!
}
`}</code></pre>
    <p>{`If we call these builders for a string, number, or boolean value, we will get back Babel-ready nodes.`}</p>
    <h4>{`JSX Text, Identifier, and Closing Element`}</h4>
    <p>{`These next three types are also pretty straightforward.`}</p>
    <p>{`The `}<a parentName="p" {...{
        "href": "https://github.com/babel/babel/blob/master/packages/babel-types/src/definitions/jsx.js#L172"
      }}>{`JSXText type`}</a>{` also only requires a value of type string, so:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`{
  type: 'JSXText',
  value: 'aString'
}
`}</code></pre>
    <p><a parentName="p" {...{
        "href": "https://github.com/babel/babel/blob/master/packages/babel-types/src/definitions/jsx.js#L95"
      }}>{`JSXIdentifier`}</a>{` has a `}<inlineCode parentName="p">{`name`}</inlineCode>{` property that's a string instead of a `}<inlineCode parentName="p">{`value`}</inlineCode>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`{
  type: \`JSXIdentifier\`,
  name: 'aString'
}
`}</code></pre>
    <p>{`The `}<a parentName="p" {...{
        "href": "https://github.com/babel/babel/blob/master/packages/babel-types/src/definitions/jsx.js#L28"
      }}><inlineCode parentName="a">{`JSXClosingElement`}</inlineCode></a>{` is more of the same, requiring only a name that's a string:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`{
  type: 'JSXClosingElement',
  name: 'aString'
}
`}</code></pre>
    <p>{`Let's build out the helpers for these, too:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`// json-to-jsx.js
const generate = require('@babel/generator').default;

// Helpers
// From above
const buildStringLiteral = value => ({type: 'StringLiteral', value})
const buildBooleanLiteral = value => ({type: 'BooleanLiteral', value})
const buildNumericLiteral = value => ({type: 'NumericLiteral', value})

// NEW
const buildJSXText = value => ({ type: 'JSXText', value})
const buildJSXIdentifier = name => ({type: 'JSXIdentifier', name})
const buildJSXClosingElement = name => ({type: 'JSXIdentifier', name})

const transform = ({input}) => {...}

const toJSX = input => {...}
`}</code></pre>
    <h3>{`JSXExpressionContainer`}</h3>
    <p>{`Next up, we've getting slightly more complex with the `}<a parentName="p" {...{
        "href": "https://github.com/babel/babel/blob/master/packages/babel-types/src/definitions/jsx.js#L75"
      }}><inlineCode parentName="a">{`JSXExpressionContainer`}</inlineCode></a>{`, which is a node that has an `}<inlineCode parentName="p">{`expression`}</inlineCode>{` property. What's an expression, you may be wondering. And I'm wondering a bit, too, as I can't find a formal definition in the definitions types. From what I can surmise, any type that has an `}<inlineCode parentName="p">{`alias`}</inlineCode>{` of `}<inlineCode parentName="p">{`"Expression"`}</inlineCode>{` is something that could be deemed an expression. For this particular use case of input we're using, the `}<inlineCode parentName="p">{`BooleanLiteral`}</inlineCode>{` and `}<inlineCode parentName="p">{`NumericLiteral`}</inlineCode>{` are the only types that are used as `}<inlineCode parentName="p">{`Expression`}</inlineCode>{` nodes on the `}<inlineCode parentName="p">{`JSXExpressionContainer`}</inlineCode>{`s. So, if we run into the `}<inlineCode parentName="p">{`propName={...}`}</inlineCode>{` with the curly braces, we know we need to build a `}<inlineCode parentName="p">{`JSXExpressionContainer`}</inlineCode>{`, which has an expression value of either the `}<inlineCode parentName="p">{`BooleanLiteral`}</inlineCode>{` or `}<inlineCode parentName="p">{`NumericLiteral`}</inlineCode>{` types that we defined above. As we make this more robust, we would want our builder helpers to handle more than just these two types of literals.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`{
  type: 'JSXExpressionContainer',
  expression: {
    type: 'BooleanLiteral', // or NumericLiteral
    value: true|false       // and a number
  }
}
`}</code></pre>
    <p>{`A helper we could use here:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`// json-to-jsx.js
const generate = require('@babel/generator').default;

// Helpers
// From above
const buildStringLiteral = value => ({type: 'StringLiteral', value})
const buildBooleanLiteral = value => ({type: 'BooleanLiteral', value})
const buildNumericLiteral = value => ({type: 'NumericLiteral', value})
const buildJSXText = value => ({ type: 'JSXText', value})
const buildJSXIdentifier = name => ({type: 'JSXIdentifier', name})
const buildJSXClosingElement = name => ({type: 'JSXIdentifier', name})

// NEW!
const buildJSXExpression = expression => ({type: 'JSXExpressionContainer', expression})

const transform = ({input}) => {...}

const toJSX = input => {...}
`}</code></pre>
    <h3>{`JSXAttribute`}</h3>
    <p>{`In the AST from the explorer, we can see that there is an array of `}<inlineCode parentName="p">{`attributes`}</inlineCode>{` with type `}<inlineCode parentName="p">{`JSXAttribute`}</inlineCode>{`, which represent the props of the input object (`}<inlineCode parentName="p">{`className`}</inlineCode>{`, `}<inlineCode parentName="p">{`numberThing`}</inlineCode>{`, and `}<inlineCode parentName="p">{`isTrue`}</inlineCode>{`). We don't have any function or object values as props yet - just handling booleans, strings, and numbers. Again, as we build this out into something more robust, we'd want our helpers to handle the additional cases.`}</p>
    <p>{`The `}<inlineCode parentName="p">{`JSXAttribute`}</inlineCode>{` type is a little more complex:`}</p>
    <pre><code parentName="pre" {...{}}>{`defineType("JSXAttribute", {
  visitor: ["name", "value"],
  aliases: ["JSX", "Immutable"],
  fields: {
    name: {
      validate: assertNodeType("JSXIdentifier", "JSXNamespacedName"),
    },
    value: {
      optional: true,
      validate: assertNodeType(
        "JSXElement",
        "JSXFragment",
        "StringLiteral",
        "JSXExpressionContainer",
      ),
    },
  },
});
`}</code></pre>
    <p>{`To build an attribute, we need to have a `}<inlineCode parentName="p">{`name`}</inlineCode>{` that is of type `}<inlineCode parentName="p">{`JSXIdentifier`}</inlineCode>{` (we aren't going to be using `}<inlineCode parentName="p">{`JSXNamespacedName`}</inlineCode>{` in our basic version), and the value needs to be `}<inlineCode parentName="p">{`StringLiteral`}</inlineCode>{` or `}<inlineCode parentName="p">{`JSXExpressionContainer`}</inlineCode>{` (we're not supporting `}<inlineCode parentName="p">{`JSXElement`}</inlineCode>{`s or `}<inlineCode parentName="p">{`JSXFragment`}</inlineCode>{`s as prop values in this example, either). And above, we just decided that the expression container can be either the `}<inlineCode parentName="p">{`BooleanLiteral`}</inlineCode>{` or the `}<inlineCode parentName="p">{`NumericLiteral`}</inlineCode>{`. So, this is where we can start to use some of our little helpers.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`// json-to-jsx.js
const generate = require('@babel/generator').default;

// Helpers
// From above
const buildStringLiteral = value => ({type: 'StringLiteral', value})
const buildBooleanLiteral = value => ({type: 'BooleanLiteral', value})
const buildNumericLiteral = value => ({type: 'NumericLiteral', value})
const buildJSXText = value => ({ type: 'JSXText', value})
const buildJSXIdentifier = name => ({type: 'JSXIdentifier', name})
const buildJSXClosingElement = name => ({type: 'JSXIdentifier', name})
const buildJSXExpression = expression => ({type: 'JSXExpressionContainer', expression})

// NEW!
const buildJSXAttribute = ({name, value}) => {
  if (typeof value === 'string') {
    return {
      type: 'JSXAttribute',
      name: buildJSXIidentifier(name),
      value: buildStringLiteral(value)
    }
  }

  if (typeof value === 'boolean') {
    return {
      type: 'JSXAttribute',
      name: buildJSXIdentifier(name),
      value: buildJSXExpression(buildBooleanLiteral(value))
    }
  }

  if (typeof value === 'boolean') {
    return {
      type: 'JSXAttribute',
      name: buildJSXIdentifier(name),
      value: buildJSXExpression(buildNumericLiteral(value))
    }
  }

  throw new TypeError(\`\${value} is an unsupported type: \${typeof value}\`)
}

const transform = ({input}) => {...}

const toJSX = input => {...}
`}</code></pre>
    <p>{`There's certainly some repetition here with the `}<inlineCode parentName="p">{`type`}</inlineCode>{` and `}<inlineCode parentName="p">{`name`}</inlineCode>{`, so we could extract a `}<inlineCode parentName="p">{`buildJSXAttributeValue`}</inlineCode>{` that does the type checking, but I'll leave that undone here.`}</p>
    <h3>{`JSXOpeningElement`}</h3>
    <p>{`Whew, we're almost done - just the `}<inlineCode parentName="p">{`JSXOpeningElement`}</inlineCode>{` and `}<inlineCode parentName="p">{`JSXElement`}</inlineCode>{` left.`}</p>
    <p>{`The `}<a parentName="p" {...{
        "href": "https://github.com/babel/babel/blob/master/packages/babel-types/src/definitions/jsx.js#L131"
      }}><inlineCode parentName="a">{`JSXOpeningElement`}</inlineCode></a>{` has a `}<inlineCode parentName="p">{`name`}</inlineCode>{` (which is a `}<inlineCode parentName="p">{`JSXEpression`}</inlineCode>{`), an `}<inlineCode parentName="p">{`attributes`}</inlineCode>{` array where each item is a `}<inlineCode parentName="p">{`JSXAttribute`}</inlineCode>{`, and a `}<inlineCode parentName="p">{`selfClosing`}</inlineCode>{` property, which describes whether the tag is self-closing. For the purposes of this input, a `}<inlineCode parentName="p">{`div`}</inlineCode>{`, the tag is not self-closing. The tags that might be self-closing are `}<inlineCode parentName="p">{`img`}</inlineCode>{`, `}<inlineCode parentName="p">{`br`}</inlineCode>{`, `}<inlineCode parentName="p">{`hr`}</inlineCode>{`, `}<inlineCode parentName="p">{`iframe`}</inlineCode>{`, etc.`}</p>
    <p>{`So, to build the `}<inlineCode parentName="p">{`JSXOpeningElement`}</inlineCode>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`// json-to-jsx.js
const generate = require('@babel/generator').default;

// Helpers
// From above
const buildStringLiteral = value => ({type: 'StringLiteral', value})
const buildBooleanLiteral = value => ({type: 'BooleanLiteral', value})
const buildNumericLiteral = value => ({type: 'NumericLiteral', value})
const buildJSXText = value => ({ type: 'JSXText', value})
const buildJSXIdentifier = name => ({type: 'JSXIdentifier', name})
const buildJSXClosingElement = name => ({type: 'JSXIdentifier', name})
const buildJSXExpression = expression => ({type: 'JSXExpressionContainer', expression})
const buildJSXAttribute = ({name, value}) => {...}

// NEW!
const SELF_CLOSING_TAGS = ['img', 'br', 'hr', 'iframe'];
// attributes map to the props of the input, which look like:
// props: {
//   className: 'hello',
//   numberThing: 5,
//   isTrue: true
// }
const buildJSXOpeningElement = ({name, attributes}) => {
  return {
    type: 'JSXOpeningElement',
    name: buildJSXIdentifier(name),
    attributes: Object.entries(attributes).map(([attributeName, attributeValue]) => {
      return buildJSXAttribute({name: attributeName, value: attributeValue})
    }),
    selfClosing: SELF_CLOSING_TAGS.includes(name)
  }
}

const transform = ({input}) => {...}

const toJSX = input => {...}
`}</code></pre>
    <h3>{`JSXElement`}</h3>
    <p>{`Alrighty! Let's finish it out with the `}<inlineCode parentName="p">{`JSXElement`}</inlineCode>{`, which has to be the parent of all of these nodes.`}</p>
    <p>{`Looking at the `}<a parentName="p" {...{
        "href": "https://github.com/babel/babel/blob/master/packages/babel-types/src/definitions/jsx.js#L42"
      }}>{`type definition`}</a>{`, we see that we need an `}<inlineCode parentName="p">{`openingElement`}</inlineCode>{` (`}<inlineCode parentName="p">{`JSXOpeningElement`}</inlineCode>{`), a `}<inlineCode parentName="p">{`closingElement`}</inlineCode>{` (`}<inlineCode parentName="p">{`JSXClosingElement`}</inlineCode>{`), `}<inlineCode parentName="p">{`children`}</inlineCode>{` (an array of `}<inlineCode parentName="p">{`JSXText`}</inlineCode>{` or `}<inlineCode parentName="p">{`JSXElement`}</inlineCode>{`s), and the `}<inlineCode parentName="p">{`selfClosing`}</inlineCode>{` attribute.`}</p>
    <p>{`Since we've been building these helpers all along, building out this element isn't too tough. We can put this element builder code into the `}<inlineCode parentName="p">{`transform`}</inlineCode>{` function we've been ignoring!`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`// json-to-jsx.js
const generate = require('@babel/generator').default;

// Helpers
// From above
const SELF_CLOSING_TAGS = ['img', 'br', 'hr', 'iframe'];
const buildStringLiteral = value => ({type: 'StringLiteral', value})
const buildBooleanLiteral = value => ({type: 'BooleanLiteral', value})
const buildNumericLiteral = value => ({type: 'NumericLiteral', value})
const buildJSXText = value => ({ type: 'JSXText', value})
const buildJSXIdentifier = name => ({type: 'JSXIdentifier', name})
const buildJSXClosingElement = name => ({type: 'JSXIdentifier', name})
const buildJSXExpression = expression => ({type: 'JSXExpressionContainer', expression})
const buildJSXAttribute = ({name, value}) => {...}
const buildJSXOpeningElement = ({name, attributes}) => {...}

// NEW!
const transform = ({input, target = 'root'}) => {
  const elementToTransform = input[target];
  const {children, innerText, props, type} = elementToTransform;

  let transformedChildren;
  if (innerText) {
    // no children; just the innerText,
    // This is the case for the example input we've been working from
    // The JSXElement children are a one-element array with JSXText
    transformedChildren = [buildJSXText(innerText)]
  } else {
    // we have children to consider.
    // The JSXElement's children is just a map of recursive calls to
    // transform with the child id as the target
    transformedChildren = children.map(childId => {
      return transform({input, target: childId})
    })
  }

  // return the AST tree!
  return {
    type: 'JSXElement',
    openingElement: buildJSXOpeningElement({name: type, attributes: props}),
    children: transformedChildren,
    ...(!SELF_CLOSING_TAGS.includes(type) && {closingElement: buildJSXClosingElement(type)})
  }
}

const toJSX = input => {...}
`}</code></pre>
    <h2>{`Generating JavaScript`}</h2>
    <p>{`We did it! We run our `}<inlineCode parentName="p">{`transform`}</inlineCode>{` method on our input object and return a Babel-ready JSX AST. So let's use it in our `}<inlineCode parentName="p">{`toJSX`}</inlineCode>{` function and get out a JavaScript string. We can use the Babel built-in generator on the ast we've built, and we should be good to go.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`// json-to-jsx.js
const generate = require('@babel/generator').default;

// Helpers
// From above
const SELF_CLOSING_TAGS = ['img', 'br', 'hr', 'iframe'];
const buildStringLiteral = value => ({type: 'StringLiteral', value})
const buildBooleanLiteral = value => ({type: 'BooleanLiteral', value})
const buildNumericLiteral = value => ({type: 'NumericLiteral', value})
const buildJSXText = value => ({ type: 'JSXText', value})
const buildJSXIdentifier = name => ({type: 'JSXIdentifier', name})
const buildJSXClosingElement = name => ({type: 'JSXIdentifier', name})
const buildJSXExpression = expression => ({type: 'JSXExpressionContainer', expression})
const buildJSXAttribute = ({name, value}) => {...}
const buildJSXOpeningElement = ({name, attributes}) => {...}
const transform = ({input, target = 'root'}) => {...}

// NEW!
const toJSX = input => {
  const ast = transform({input});
  return generate(ast).code;
}
`}</code></pre>
    <p>{`And there we have it! Once the AST is in a form that Babel can read, all we have to do is feed our AST into the `}<inlineCode parentName="p">{`generate`}</inlineCode>{` function.`}</p>
    <h2>{`Where to go from here`}</h2>
    <p>{`Alright, so now we can take an object and convert it into JSX code, after exploring the AST and babel types. Building a new builder helper for each type would be a bit of a pain if we were to continue on with this and make it more robust. The `}<a parentName="p" {...{
        "href": "https://babeljs.io/docs/en/babel-types"
      }}>{`babel-types`}</a>{` package already has a builder for every type, and we can just use those.`}</p>
    <p>{`At this point, if we stick to the above structure of the `}<inlineCode parentName="p">{`input`}</inlineCode>{`, we could store an entire webpage as JSON. We could use a `}<inlineCode parentName="p">{`prebuild`}</inlineCode>{` script to take the JSON, parse it into a JS object, feed that object into our `}<inlineCode parentName="p">{`toJSX`}</inlineCode>{` function, and write that output into a `}<inlineCode parentName="p">{`.js`}</inlineCode>{` file. And then with a static site generator, we could `}<inlineCode parentName="p">{`build`}</inlineCode>{` this brand-new page into small, fast HTML page.`}</p>
    <p>{`We wouldn't be the entire way there, as we'd still need to figure out how to input `}<inlineCode parentName="p">{`const React = import('react')`}</inlineCode>{` at the top of the file, along with any other packages or custom components we want to use. We `}<em parentName="p">{`could`}</em>{` just hard-code that in to the top of every top-level `}<inlineCode parentName="p">{`toJSX`}</inlineCode>{` call. Or we could build it with a `}<a parentName="p" {...{
        "href": "https://github.com/jamiebuilds/babel-handbook/blob/master/translations/en/plugin-handbook.md#babel-template"
      }}><inlineCode parentName="a">{`babel-template`}</inlineCode></a>{`.`}</p>
    <p>{`And once we have the basic `}<inlineCode parentName="p">{`js`}</inlineCode>{` pages with the JSX, we could use a static site generator like Gatsby to build the pages while wrapping them in a `}<inlineCode parentName="p">{`Layout`}</inlineCode>{` component. We could go from JSON to a full, lightning-fast Gatsby site. If I build that, I'll keep y'all updated.`}</p>
    <p>{`Thanks for following along! ✌️`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      